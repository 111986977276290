import React from 'react';
import BecomePartnercard from '../../../components/BecomePartnercard';

const BecomeApartner = () => {
	return (
		<div className="becomePartner">
			<div className="becomePartner__header">
				<h1 className="becomePartner__header--headerText">
					Become an Enterprise Partner
				</h1>
				<p className="becomePartner__header--bodyText">
					Partner with us to resell fitness center passes to your customers,
					boost your product offerings, and earn on every sale
				</p>
			</div>
			<div className="becomePartner__body">
				<div className="becomePartner__body--cards">
					<BecomePartnercard
						icon={'ideaBox'}
						mainText={'Expand Your Offerings with Fitness Passes'}
						subText={
							'Give your customers more value by offering fitness plans with access over 100 gyms/fitness centres.'
						}
						iconWidth="71"
						iconHeight="70"
						color="lightBlue"
					/>
					<BecomePartnercard
						icon={'bigFile'}
						mainText={'Earn Commissions on Every Pass Sold'}
						subText={
							'Grow your revenue by earning commissions for every sale you generate.'
						}
						iconWidth="45"
						iconHeight="57"
						color="lightGreen"
					/>
					<BecomePartnercard
						icon={'api'}
						mainText={'Seamless Integration with Our API'}
						subText={
							'Your customers can enjoy access to  fitness centers directly from your platform with API integration.'
						}
						iconWidth="54"
						iconHeight="48"
						color="purple"
					/>
				</div>
				<a
					className="btn btn--primary btn--block mt-40"
					href={
						'https://docs.google.com/forms/d/e/1FAIpQLSeqx1Zk9l_1NPLjE3CH_v41KHw89sqcpfN0K16aSQt3erezfg/viewform'
					}
					target="_blank"
					rel="noopener noreferrer"
				>
					{'GET STARTED'}
				</a>
			</div>
		</div>
	);
};

export default BecomeApartner;
