import React from 'react';
import { fmtCurrency } from '../utils/helpers';
import Icon from './Icon';

const EnterpriseCard = ({ pass, setOpenSideModal, setSelectedPass }) => {
	return (
		<div>
			<div className="plan__card" key={pass?.id}>
				<div className="plan__card--header">
					<span>
						<Icon width={32} height={32} id={'bluePassCircle'} />
					</span>
					<div className="header__content">
						<h2>{pass?.name}</h2>
						<p>{pass?.description}</p>
					</div>
				</div>

				<div className="plan_nameID">
					<div className="plan__card--body">
						<p className="price">
							{fmtCurrency(pass?.price)}
							{/* <span>/{pass?.duration}</span> */}
						</p>
					</div>
					<span>{pass?.id}</span>
				</div>
				<div className="plan__card--bottom">
					<button
						variant="primary"
						type="submit"
						// className=" "
						className="plan__btn"
						block
						onClick={() => {
							setSelectedPass(pass);
							setOpenSideModal(true);
						}}
					>
						<span className="btn-text add-to-cart">See Eligible Gyms</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default EnterpriseCard;
