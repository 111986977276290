import React from 'react';

const ClientCheckBox = ({
	handleChange,
	useClientIdAsPassCode,
	sendPassCodeViaEmail,
}) => {
	
	return (
		<>
			<div className="checkbox">
				<label>
					<input
						type="checkbox"
						id="useClientIdAsPassCode"
						onChange={handleChange}
						name="useClientIdAsPassCode"
						value={useClientIdAsPassCode}
					/>
					Use Client ID as Passcode
				</label>
				<label>
					<input
						type="checkbox"
						id="sendPassCodeViaEmail"
						onChange={handleChange}
						name="sendPassCodeViaEmail"
						value={sendPassCodeViaEmail}
					/>
					Send Passcode via Email
				</label>
			</div>
		</>
	);
};

export default ClientCheckBox;
