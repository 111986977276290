import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';
import { toast } from 'react-hot-toast';

const initialState = {
	isLoading: false,
	resellerPasses: [],
	transations: [],
	apiKeyForResellers: {},
	apiKeyLoading: false,
	eligibleGyms: {},
	submitingFrom: false,
	resellersClients: [],
	individualClients: {},
	loadSideModal: false,
};

const notify = (message) => toast.error(message);
const notifySuccess = (message) => toast.success(message);

export const getResellablePass = createAsyncThunk(
	'pass/getResellablePass',
	async (_, thunkAPI) => {
		try {
			const resp = await customFetch.get('business/resellable-pass');
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const onboardBusiness = createAsyncThunk(
	'pass/onboardBusiness',
	async (_, thunkAPI) => {
		try {
			const resp = await customFetch.post('business/reseller');
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const resellerTransactions = createAsyncThunk(
	'pass/resellerTransactions',
	async (_, thunkAPI) => {
		try {
			const resp = await customFetch.get(
				'business/reseller/transactions?page=0&size=50'
			);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const resellerApisKey = createAsyncThunk(
	'pass/resellerApisKey',
	async (_, thunkAPI) => {
		try {
			const resp = await customFetch.put('business/reseller/api-key');
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const getEligibleGyms = createAsyncThunk(
	'pass/getEligibleGyms',
	async (id, thunkAPI) => {
		try {
			const resp = await customFetch.get(
				`business/resellable-pass/${id}/providers?page=0&size=50`
			);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const submitClientsWithForm = createAsyncThunk(
	'pass/submitClientsWithForm',
	async (payload, thunkAPI) => {
		try {
			const resp = await customFetch.post(`business/reseller/client`, payload);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const submitClientsWithCSV = createAsyncThunk(
	'pass/submitClientsWithCSV',
	async (file, thunkAPI) => {
		try {
			const resp = await customFetch.post(
				'business/reseller/clients/upload',
				file
			);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const getAllRessellersClients = createAsyncThunk(
	'pass/getAllRessellersClients',
	async (_, thunkAPI) => {
		try {
			const resp = await customFetch.get(
				'business/reseller/clients?page=0&size=100'
			);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const updateResellerPass = createAsyncThunk(
	'pass/updateResellerPass',
	async (payload, thunkAPI) => {
		try {
			const resp = await customFetch.post(
				'business/reseller/client/update',
				payload
			);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const updateResellerDate = createAsyncThunk(
	'pass/updateResellerDate',
	async (payload, thunkAPI) => {
		try {
			const resp = await customFetch.post(
				'business/reseller/client/update/expiry',
				payload
			);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const resellerClientById = createAsyncThunk(
	'pass/resellerClientById',
	async (resellerClientId, thunkAPI) => {
		try {
			const resp = await customFetch.get(
				`/business/reseller/client/${resellerClientId}`
			);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);
export const resellerClientsByPass = createAsyncThunk(
	'pass/resellerClientsByPass',
	async (resellerPassId, thunkAPI) => {
		try {
			const resp = await customFetch.get(
				`/business/reseller/clients/pass/${resellerPassId}`
			);
			return resp?.data;
		} catch (error) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
);

const resellersSlice = createSlice({
	name: 'resellers',
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(getResellablePass.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getResellablePass.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.resellerPasses = payload;
			})
			.addCase(getResellablePass.rejected, (state, { payload }) => {
				state.isLoading = false;
				notify(payload);
			})
			.addCase(onboardBusiness.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(onboardBusiness.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				notifySuccess(payload?.message);
			})
			.addCase(onboardBusiness.rejected, (state, { payload }) => {
				state.isLoading = false;
				notify(payload);
			})
			.addCase(resellerTransactions.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(resellerTransactions.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.transations = payload;
			})
			.addCase(resellerTransactions.rejected, (state, { payload }) => {
				state.isLoading = false;
				notify(payload);
			})
			.addCase(resellerApisKey.pending, (state) => {
				state.apiKeyLoading = true;
			})
			.addCase(resellerApisKey.fulfilled, (state, { payload }) => {
				state.apiKeyLoading = false;
				state.apiKeyForResellers = payload;
				notifySuccess(payload?.message);
			})
			.addCase(resellerApisKey.rejected, (state, { payload }) => {
				state.apiKeyLoading = false;
				notify(payload);
			})
			.addCase(getEligibleGyms.pending, (state) => {
				state.apiKeyLoading = true;
			})
			.addCase(getEligibleGyms.fulfilled, (state, { payload }) => {
				state.apiKeyLoading = false;
				state.eligibleGyms = payload;
			})
			.addCase(getEligibleGyms.rejected, (state, { payload }) => {
				state.apiKeyLoading = false;
				notify(payload);
			})
			.addCase(submitClientsWithForm.pending, (state) => {
				state.submitingFrom = true;
			})
			.addCase(submitClientsWithForm.fulfilled, (state, { payload }) => {
				state.submitingFrom = false;
				notifySuccess(payload?.message);
			})
			.addCase(submitClientsWithForm.rejected, (state, { payload }) => {
				state.submitingFrom = false;
				notify(payload);
			})
			.addCase(submitClientsWithCSV.pending, (state) => {
				state.submitingFrom = true;
			})
			.addCase(submitClientsWithCSV.fulfilled, (state, { payload }) => {
				state.submitingFrom = false;
				notifySuccess(payload?.message);
			})
			.addCase(submitClientsWithCSV.rejected, (state, { payload }) => {
				state.submitingFrom = false;
				notify(payload);
			})
			.addCase(getAllRessellersClients.pending, (state) => {
				state.submitingFrom = true;
			})
			.addCase(getAllRessellersClients.fulfilled, (state, { payload }) => {
				state.submitingFrom = false;
				state.resellersClients = payload;
			})
			.addCase(getAllRessellersClients.rejected, (state, { payload }) => {
				state.submitingFrom = false;
				notify(payload);
			})
			.addCase(updateResellerPass.pending, (state) => {
				state.loadSideModal = true;
			})
			.addCase(updateResellerPass.fulfilled, (state, { payload }) => {
				state.loadSideModal = false;
				notifySuccess(payload?.message);
			})
			.addCase(updateResellerPass.rejected, (state, { payload }) => {
				state.loadSideModal = false;
				notify(payload);
			})
			.addCase(updateResellerDate.pending, (state) => {
				state.loadSideModal = true;
			})
			.addCase(updateResellerDate.fulfilled, (state, { payload }) => {
				state.loadSideModal = false;
				notifySuccess(payload?.message);
			})
			.addCase(updateResellerDate.rejected, (state, { payload }) => {
				state.loadSideModal = false;
				notify(payload);
			})
			.addCase(resellerClientsByPass.pending, (state) => {
				state.submitingFrom = true;
			})
			.addCase(resellerClientsByPass.fulfilled, (state, { payload }) => {
				state.submitingFrom = false;
				state.resellersClients = payload;
			})
			.addCase(resellerClientsByPass.rejected, (state, { payload }) => {
				state.submitingFrom = false;
				notify(payload);
			})
			.addCase(resellerClientById.pending, (state) => {
				state.loadSideModal = true;
			})
			.addCase(resellerClientById.fulfilled, (state, { payload }) => {
				state.loadSideModal = false;
				state.individualClients = payload;
			})
			.addCase(resellerClientById.rejected, (state, { payload }) => {
				state.loadSideModal = false;
				notify(payload);
			});
	},
});

export default resellersSlice.reducer;
onboardBusiness;
