import React, { useState } from 'react';
import Icon from './Icon';
import Button from './Button';

const SearchAddContainer = ({
	searchInput,
	changeInput,
	handleCLick,
	btnTitle,
	filterOptions,
	selectedFilter,
	setSelectedFilter,
	flipSides = false,
	isFilterObject = false, // if this is true pass in obj in name id format pls 🙏🙏
	addAllfilter = false,
}) => {
	const [openFilterList, setOpenFilterList] = useState(false);

	return (
		<div className="employee__header">
			<div
				className={`employee__header--container ${
					flipSides ? 'flipSides' : ''
				}`}
			>
				<div className="employee__header--search">
					<Icon width="16" height="16" id="search" />
					<input
						type="text"
						id="savingsAmount"
						value={searchInput}
						onChange={changeInput}
						placeholder="Search name"
					/>
				</div>
				{filterOptions && (
					<div
						className="employee__header--filterContainer"
						onMouseLeave={() => setOpenFilterList(false)}
					>
						<div
							className="employee__header--filter"
							onMouseOver={() => setOpenFilterList(true)}
						>
							{isFilterObject ? (
								<p>
									{selectedFilter?.name ? selectedFilter?.name : 'Filter by'}
								</p>
							) : (
								<p>{selectedFilter ? selectedFilter : 'Filter by'}</p>
							)}
							<Icon id="down-caret" width="10" height="4" />
						</div>
						{openFilterList && (
							<div className="filterList">
								<ul>
									{addAllfilter && (
										<li
											onClick={() => {
												if (isFilterObject) {
													setSelectedFilter({ name: 'All', id: 'all' });
												} else {
													setSelectedFilter('All');
												}
											}}
											key={'all'}
										>
											All
										</li>
									)}
									{filterOptions.map((item, index) => (
										<>
											<li
												onClick={() => {
													setSelectedFilter(item);
												}}
												key={index}
											>
												{item?.name ? item?.name : item}
											</li>
										</>
									))}
								</ul>
							</div>
						)}
					</div>
				)}
			</div>
			{btnTitle && (
				<div className="employee__header--btn">
					<Button
						variant="primary"
						size="sm"
						className="btn--block"
						onClick={handleCLick}
						// disabled={loader}
					>
						{btnTitle}
					</Button>
				</div>
			)}
		</div>
	);
};

export default SearchAddContainer;
