import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthLayout from '../layout/AuthLayout';
import Login from '../pages/auth/Login';
import Signup from '../pages/auth/SignUp';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';
import { RequiredAuth } from '../hooks/RequiredAuth';
import VerifyMail from '../pages/auth/mailVerfications/VerifyMail';
import ForgotPasswordMail from '../pages/auth/mailVerfications/ForgotPasswordMail';
import BrokenMail from '../pages/auth/mailVerfications/BrokenMail';
import ResetPawordMail from '../pages/auth/mailVerfications/ResetPawordMail';
import DashboardLayout from '../layout/DashboardLayout';
import Plans from '../pages/app/plans';
import Settings from '../pages/app/settings/SettingsLayout';
import Employees from '../pages/app/employees';
import Accounts from '../pages/app/settings/Accounts';
import ContactInfo from '../pages/app/settings/ContactInfo';
import Security from '../pages/app/settings/Security';
import PlanList from '../pages/app/plans/PlanList';
import AllPlans from '../pages/app/plans/AllPlans';
import PlanCreation from '../pages/app/plans/PlanCreation';
import PlanDetails from '../pages/app/plans/PlanDetails';
import VerifyingMail from '../pages/auth/mailVerfications/VerifyingMail';
import EmployeeSignUp from '../pages/auth/EmployeeSignUp';
import ExisitingUserVerify from '../pages/auth/ExisitingUserVerify';
import Card from '../pages/app/settings/Card';
import Wallet from '../pages/app/Wallet';
import Page404 from '../pages/404';
import Documents from '../pages/app/settings/Documents';
import EmployeeDetail from '../pages/app/employees/EmployeeDetail';
import BecomeApartner from '../pages/app/enterprise/BecomeApartner';
import EnterPrisePartner from '../pages/app/enterprise/EnterPrisePartner';
import ClinetsInfo from '../pages/app/enterprise/ClinetsInfo';
import SingleDashBoardHeader from '../layout/SingleDashBoardHeader';
import Clients from '../pages/app/enterprise/Clients';

const RouteSwitch = () => {
	return (
		<BrowserRouter>
			<Routes>
				{/* Routes Protected by authorization */}
				<Route element={<RequiredAuth />}>
					<Route path="/" element={<DashboardLayout />}>
						<Route path="/plan" element={<Plans />}>
							<Route index element={<PlanList />} />
							<Route path="/plan/all-plans" element={<AllPlans />} />
							<Route path="/plan/plans-creation" element={<PlanCreation />} />
							<Route path="/plan/plans-details/:id" element={<PlanDetails />} />
						</Route>
						<Route path="/employees" element={<Employees />} />
						<Route path="/employees/:id" element={<EmployeeDetail />} />
						<Route path="/credit" element={<Wallet />} />
						<Route path="/become-partner" element={<BecomeApartner />} />
						<Route path="/enterprise" element={<EnterPrisePartner />} />
						<Route path="/settings" element={<Settings />}>
							<Route index element={<Accounts />} />
							<Route path="/settings/contact-info" element={<ContactInfo />} />
							<Route path="/settings/security" element={<Security />} />
							<Route path="/settings/card" element={<Card />} />
							<Route path="/settings/documents" element={<Documents />} />
						</Route>
					</Route>
					<Route element={<SingleDashBoardHeader />}>
						<Route path="/add-clients" element={<ClinetsInfo />} />
						<Route path="/clients" element={<Clients />} />
					</Route>
				</Route>
				{/* Auth Routes */}
				<Route path="/auth" element={<AuthLayout />}>
					<Route path="/auth/login" element={<Login />} />
					<Route path={'/auth/signup'} element={<Signup />} />
					<Route path={'/auth/forgot-password'} element={<ForgotPassword />} />
					<Route path={'/auth/reset-password'} element={<ResetPassword />} />
					<Route path={'/auth/verify-mail'} element={<VerifyMail />} />
					<Route path={'/auth/verifying-mail'} element={<VerifyingMail />} />
					<Route
						path={'/auth/forgotPassword-mail'}
						element={<ForgotPasswordMail />}
					/>
					<Route path={'/auth/broken-mail'} element={<BrokenMail />} />
					<Route path={'/auth/reset-mail'} element={<ResetPawordMail />} />
					<Route
						path="/auth/verifyExisitingUser"
						element={<ExisitingUserVerify />}
					/>
				</Route>
				<Route path="/auth/employee-signup" element={<EmployeeSignUp />} />

				<Route path="*" element={<Page404 />} />
			</Routes>
		</BrowserRouter>
	);
};

export default RouteSwitch;
