import React, { useEffect, useState } from 'react';
import Icon from './Icon';
import { navigations } from '../utils/navigations';
import { useDispatch } from 'react-redux';
import { logoutCurrnentUser } from '../features/user/userSlice';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

const DashboardHeader = ({
	splitLocation,
	setSlidingSidebar,
	slidingSidebar,
}) => {
	const [openDropdown, setDropdown] = useState(false);
	const dispatch = useDispatch();
	// const navigate = useNavigate();
	const { businessDetails } = useSelector((store) => store.user);

	const logout = () => {
		// navigate('/auth/login');
		dispatch(logoutCurrnentUser());
	};

	const [navTitle, setNavTitle] = useState();

	useEffect(() => {
		const getNavTitle = () => {
			const title = navigations.find((navigation) => {
				return navigation.link === splitLocation[1];
			});

			setNavTitle(title);
		};

		getNavTitle();
	}, [splitLocation]);

	return (
		<>
			<nav className={`topnav`}>
				<div className="topnav--wrapper">
					<div className="brand">
						<Icon
							id={'hamburger'}
							width={'24'}
							height={'21'}
							onClick={() => setSlidingSidebar(!slidingSidebar)}
						/>
						<h2>
							{navTitle
								? navTitle?.name
								: splitLocation[1] === 'enterprise' ||
								  splitLocation[1] === 'become-partner'
								? 'Enterprise Partner'
								: splitLocation[1]}
						</h2>
					</div>

					<div
						className="topnav-right d-iflx al-i-c"
						onMouseOver={() => setDropdown(true)}
						onMouseLeave={() => setDropdown(false)}
					>
						<div className="avatar-circle">
							<div className="avatar-circle__placeholder iflx-center-center">
								<p className="avatar-circle__initials iflx-center-center">
									{businessDetails?.businessName.charAt(0)}
								</p>
							</div>
						</div>
						<div className="d-iflx al-i-c">
							<p className="username mr-8">{businessDetails?.businessName}</p>
							<Icon width="8px" height="5px" id="drop-icon" />

							{openDropdown && (
								<ul className="topnav__dropdown">
									<li onClick={logout}>
										<span className="logoutBtn">Logout</span>
									</li>
								</ul>
							)}
						</div>
					</div>
				</div>
			</nav>
		</>
	);
};

export default DashboardHeader;
