import React, { useEffect, useState, useRef } from 'react';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';
import AddEmployeeModal from '../../../components/modals/AddEmployeeModal';
import ModalResponse from '../../../components/modals/ModalResponse';
import EmployeesPlanTable from '../../../components/table/EmployeesPlanTable';
import Goback from '../../../components/Goback';
import Tooltip from '../../../components/Tooltip';
import skeletonLoading from '../../../assets/svgs/chat-thread.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEmployees } from '../../../features/employees/employeesSlice';
import AlertPopUp from '../../../components/AlertPopUp';
import {
	getBusinessCardDetails,
	planPayment,
	planPaymentWithToken,
	setPlanActiveTrue,
} from '../../../features/plan/planSlice';
import { getQueryVariable, getRandomInt } from '../../../utils/helpers';
import PaymentSuccessModal from '../../../components/modals/PaymentSuccessModal';
import ConfirmExisitingUser from '../../../components/modals/ConfirmExisitingUser';
import { faker } from '@faker-js/faker';
import MobileTable from '../../../components/modals/MobileTable';
import ConfirmPaymentWithToken from '../../../components/modals/ConfirmPaymentWithToken';

const PlanCreation = () => {
	const inputRef = useRef(null);
	const [showInputModal, setShowInputModal] = useState(false);
	const [addEmployeeModal, setAddEmployeeModal] = useState(false);
	const [addEmployeeSuccess, setAddEmployeeSuccess] = useState(false);
	const [cycleDropDown, setCycleDropDown] = useState(false);
	const [changeCycle, setChangeCycle] = useState('Monthly');
	const [editNameActive, setEditNameActive] = useState(false);
	const [users, setUsers] = useState([]);
	const [selectedEmployees, setSelectedEmployees] = useState([]);
	const [selectedBenefits, setSelectedBenefits] = useState([]);
	const [editPlanName, setEditPlanName] = useState('');
	const [employeeSearch, setEmployeeSearch] = useState('');
	const [cyclePrice, setCyclePrice] = useState(1);
	const [randomlyGeneratedString, setRandomlyGeneratedString] = useState('');
	const [hasCardModal, setHasCardModal] = useState(false);
	const [exisitingUserModal, setExisitingUserModal] = useState(false);
	const [addEmployeeDetails, setAddEmployeeDetails] = useState({});
	const { employees } = useSelector((store) => store.employees);
	const [paymentSuccess, setPaymentSuccess] = useState(false);
	const [benefitWithCoporateFlag, setBenefitWithCoporateFlag] = useState(null);
	// const [allBeneFitDuration, setAllBeneFitDuration] = useState([]);
	const { planPaymentLoading, cardDetails } = useSelector(
		(store) => store.plan
	);
	const dispatch = useDispatch();

	/*Procees Payment */
	useEffect(() => {
		dispatch(setPlanActiveTrue());
		const payload = {
			// employeeCallBackUrl: `${process.env.REACT_APP_FLANCE_APP_URL}login`,
			// businessCallBackUrl: `${window.location.origin}/auth/login`,
			status: getQueryVariable('status'),
			transactionReference: getQueryVariable('tx_ref'),
			transactionId: getQueryVariable('transaction_id'),
		};
		if (payload.status === 'successful' || payload.status === 'completed') {
			setPaymentSuccess(payload);
		}
	}, [getQueryVariable('status')]);

	/* Changing Cycles Monthly Qaurterly e.t.c */

	const handleCycleChange = (cycle) => {
		setChangeCycle(cycle);
		setCycleDropDown(false);
	};
	useEffect(() => {
		if (changeCycle === 'monthly') {
			setCyclePrice(1);
		} else if (changeCycle === 'quarterly') {
			setCyclePrice(3);
		} else if (changeCycle === 'biannually') {
			setCyclePrice(6);
		} else if (changeCycle === 'annually') {
			setCyclePrice(12);
		}
	}, [changeCycle]);

	// Set Change Cycle

	useEffect(() => {
		if (benefitWithCoporateFlag) {
			setChangeCycle(
				benefitWithCoporateFlag?.priceOptions[0].duration.toLowerCase()
			);
		}
	}, [benefitWithCoporateFlag]);

	/* Selecting Employees and FIltering Employees  by names */

	const employeeFilter = employees?.filter((user) => {
		if (!user?.planName) {
			if (
				user?.firstname?.toLowerCase().includes(employeeSearch.toLowerCase()) ||
				user?.lastname?.toLowerCase().includes(employeeSearch.toLowerCase())
			) {
				return user;
			}
		} else {
			return;
		}
	});

	useEffect(() => {
		setUsers(employeeFilter);
	}, [employeeFilter?.length]);

	// Select Employees from List of all employees

	const handleChange = (e) => {
		const { name, checked } = e.target;
		if (name === 'selectAll') {
			let tempUser = employeeFilter?.map((user) => {
				return { ...user, isChecked: checked };
			});
			setSelectedEmployees(tempUser.filter((user) => user.isChecked));
			setUsers(tempUser);
		} else {
			let tempUser = users.map((user) => {
				return user?.employeeId == name
					? { ...user, isChecked: checked }
					: user;
			});
			setSelectedEmployees(tempUser.filter((user) => user.isChecked));
			setUsers(tempUser);
		}
	};

	useEffect(() => {
		const result = localStorage.getItem('selectedBenefits');
		const benefit = result ? JSON.parse(result) : null;
		setSelectedBenefits(benefit);
	}, []);

	// Check if any of the Benefit has a cooprate flag

	useEffect(() => {
		if (selectedBenefits) {
			const cooprateBenefits = selectedBenefits.find(
				(benefit) =>
					benefit.isCorporate === true &&
					benefit?.benefitCategory === 'Health Insurance'
			);
			setBenefitWithCoporateFlag(cooprateBenefits);
		}
	}, [selectedBenefits]);

	console.log(selectedBenefits);
	/* Plan Name Auto-generated and Updated */

	const generateRandomString = () => {
		const randomNumber1 = getRandomInt(0, 9);
		const randomNumber2 = getRandomInt(0, 9);
		const birdName = faker.animal.bird().split(' ')[0];
		setRandomlyGeneratedString(`${birdName} ${randomNumber1}${randomNumber2}`);
	};
	useEffect(() => {
		generateRandomString();
	}, []);
	useEffect(() => {
		setEditPlanName(randomlyGeneratedString);
	}, [randomlyGeneratedString]);

	// Delete Selected Employees

	const handleDelete = (id) => {
		const newList = selectedEmployees.filter(
			(employees) => employees.employeeId !== id
		);
		setSelectedEmployees(newList);
	};

	const focusInput = () => {
		if (inputRef.current && !editNameActive) {
			inputRef.current.focus();
		}
	};

	// CHECK IF USER HAS CARD SAVED

	useEffect(() => {
		dispatch(getBusinessCardDetails());
	}, []);

	/*Plan Payment */

	const handleClick = () => {
		if (cardDetails?.cardTokenActive) {
			setHasCardModal(true);
		} else {
			dispatch(
				planPayment({
					planName: editPlanName,
					benefitIds: selectedBenefits.map((obj) => obj.id),
					customBenefits: [],
					planTYpe: 'CUSTOM',
					redirectUrl: window.location.href,
					employeeEmails: selectedEmployees.map((obj) => obj.email),
					customizations: {
						title: `${editPlanName} Plan Payments`,
						logo: 'https://res.cloudinary.com/flance/image/upload/v1666684602/logo/logo_bg_nc1svk.png',
					},
					duration: changeCycle.toUpperCase(),
					isRenewal: false,
					...(benefitWithCoporateFlag && { isCorporateHealthInsurance: true }),
				})
			);
		}
	};

	// PLAN PAYMENTS WITH REFRESH

	const handlePaymentWithToken = () => {
		dispatch(
			planPaymentWithToken({
				planName: editPlanName,
				benefitIds: selectedBenefits.map((obj) => obj.id),
				customBenefits: [
					// {
					// 	benefitId: 4,
					// 	amount: '100000',
					// },
				],
				planTYpe: 'CUSTOM',
				redirectUrl: window.location.href,
				employeeEmails: selectedEmployees.map((obj) => obj.email),
				customizations: {
					title: `${editPlanName} Plan Payments`,
					logo: 'https://res.cloudinary.com/flance/image/upload/v1666684602/logo/logo_bg_nc1svk.png',
				},
				...(benefitWithCoporateFlag && { isCorporateHealthInsurance: true }),
				duration: changeCycle.toUpperCase(),
				isRenewal: 'false',
			})
		).then(({ payload }) => {
			if (payload.status === 'successful' || payload.status === 'completed') {
				setHasCardModal(false);
				setPaymentSuccess(payload);
			} else {
				setHasCardModal(false);
			}
		});
	};

	const getPriceForDuration = (priceOptions, targetDuration) => {
		const targetOption = priceOptions.find(
			(option) => option.duration.toLowerCase() === targetDuration.toLowerCase()
		);

		return targetOption ? targetOption.price : null;
	};

	// Payment Calculations

	const subTotal = selectedBenefits.reduce((acc, item) => {
		if (!item?.priceOptions) {
			return acc + item.benefitPrice * selectedEmployees?.length * cyclePrice;
		}
		return (
			acc +
			getPriceForDuration(item?.priceOptions, changeCycle) *
				selectedEmployees?.length
		);
	}, 0);

	return (
		<>
			<Goback />
			<AlertPopUp />
			<div className="planCreation">
				<div className="addEmployees__section">
					<div className="addEmployees__section--header">
						<h2>Add Employees</h2>
						<p>Pick the employees you want to enjoy this crafted plan.</p>
						<p className="invite" onClick={() => setAddEmployeeModal(true)}>
							Invite a new employee
						</p>
					</div>
					<div className="addEmployees__section--body">
						<div
							className="input_section"
							onFocus={() => {
								dispatch(getAllEmployees());
								setShowInputModal(true);
							}}
							onMouseLeave={() => setShowInputModal(false)}
						>
							<input
								className="employee-input"
								type="text"
								placeholder="Enter employee"
							/>
							{showInputModal && (
								<div className="input__dropdown">
									<div className="input__dropdown--header">
										<div className="__search">
											<Icon width="16" height="16" id="search" />
											<input
												type="text"
												id="savingsAmount"
												value={employeeSearch}
												onChange={(e) => setEmployeeSearch(e.target.value)}
												// onBlur={handleBlur}
												// label="Savings Amount"
												placeholder="Search name / email"
												// name="Savings Amount"
											/>
										</div>
									</div>
									<div className="input__dropdown--body">
										{/* <p>Select all</p> */}
										<input
											type="checkbox"
											checked={!users?.some((user) => user?.isChecked !== true)}
											onChange={handleChange}
											// onChange={handleCheckboxChange}
											name="selectAll"
										/>
										<label className="#636363 text-base">Select All</label>
										{users.map((employee) => (
											<div
												className="__employee--list"
												key={employee?.employeeId}
											>
												<input
													type="checkbox"
													name={employee?.employeeId}
													checked={employee?.isChecked || false}
													onChange={handleChange}
												/>
												<div className="name">
													<h4>{`${employee?.firstname} ${employee?.lastname}`}</h4>
													<p>{employee?.email}</p>
												</div>
											</div>
										))}
									</div>
								</div>
							)}
						</div>
						<p className="pick-text">
							Tap “Enter” to add and make to a list below
						</p>
						{selectedEmployees?.length > 0 ? (
							<div className="table-section">
								<div className="table-container">
									<EmployeesPlanTable
										data={selectedEmployees}
										handleDelete={handleDelete}
									/>
									<div className="smaller__table">
										{selectedEmployees?.map((user) => (
											<MobileTable
												key={user?.id}
												email={user?.email}
												// employeeId={planDetailFilter?.employeeId}
												firstname={user?.firstname}
												lastname={user?.lastname}
												// renewalDate={plan?.endDate}
												// renewalDate={dateFormat(
												// 	planDetailFilter?.startDate,
												// 	'do MMM, yyyy' || '--'
												// )}
												planName={' '}
												threeDots={false}
												nameCircle={false}
												deleteIcon={true}
												handleDelete={() => handleDelete(user?.employeeId)}
											/>
										))}
									</div>
								</div>
							</div>
						) : (
							<div className="noEmployee__container">
								<img src={skeletonLoading} alt="loading" />
								<p>
									You have added no employees yet. Once you start adding them,
									they will show up here.
								</p>
							</div>
						)}
					</div>
				</div>
				<div className="payment__section">
					<div className="payment__section--header">
						<div className="__plan plan-name">
							<input
								type="text"
								value={editPlanName}
								className="nameEdit"
								onChange={(e) => setEditPlanName(e.target.value)}
								disabled={!editNameActive}
								ref={inputRef}
							/>
							{!editNameActive ? (
								<span
									onClick={() => {
										setEditNameActive(true);
										focusInput();
									}}
								>
									Edit Plan Name
								</span>
							) : (
								<div className="checkPlanName">
									<Icon
										id="cancel-red"
										width="16"
										height="16"
										className="pointer"
										onClick={() => {
											setEditPlanName(randomlyGeneratedString);
											setEditNameActive(false);
										}}
									/>
									<Icon
										id="check-green"
										onClick={() => setEditNameActive(false)}
										width="16"
										height="16"
										className="pointer"
									/>
								</div>
							)}
						</div>
						<div className="__plan plan-employee">
							<p>Employee Count</p>
							<span>{selectedEmployees.length}</span>
						</div>
						<div className="__plan plan-cycle">
							<p>
								Plan Cycle
								<Tooltip tipText={'How would you like to be billed.'}>
									<Icon id="info" width="12" height="12" className="pointer" />
								</Tooltip>
							</p>
							<div
								className="interval"
								onMouseLeave={() => setCycleDropDown(false)}
							>
								<div className="interval__action">
									<span>{changeCycle}</span>

									<span
										className="change"
										onClick={() => setCycleDropDown(true)}
									>
										Change
									</span>
								</div>
								{cycleDropDown && (
									<div className="intervals">
										{benefitWithCoporateFlag ? (
											benefitWithCoporateFlag?.priceOptions.map((durations) => (
												<ul key={durations?.duration}>
													<li
														onClick={() =>
															handleCycleChange(
																durations.duration.toLowerCase()
															)
														}
													>
														{durations.duration.toLowerCase()}
													</li>
												</ul>
											))
										) : (
											<ul>
												<li onClick={() => handleCycleChange('monthly')}>
													Monthly
												</li>
												<li onClick={() => handleCycleChange('quarterly')}>
													Quarterly
												</li>
												<li onClick={() => handleCycleChange('biannually')}>
													Biannually
												</li>
												<li onClick={() => handleCycleChange('annually')}>
													Annually
												</li>
											</ul>
										)}
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="payment__section--body">
						{selectedBenefits.map((benefit) => (
							<div className="__benefit-container" key={benefit?.id}>
								<div className="benefit-name">
									<h2>
										{<span>{selectedEmployees?.length}x </span>}

										{benefit?.benefitName}
									</h2>
									<p>{benefit?.benefitProvider}</p>
								</div>
								<div className="benefit-amount">
									{benefit?.priceOptions ? (
										<h2>
											₦
											{/* {benefit?.benefitPrice *
												selectedEmployees.length *
												cyclePrice} */}
											{getPriceForDuration(benefit?.priceOptions, changeCycle) *
												selectedEmployees.length}
										</h2>
									) : (
										<h2>
											₦
											{selectedEmployees.length *
												benefit?.benefitPrice *
												cyclePrice}
										</h2>
									)}
								</div>
							</div>
						))}
					</div>
					<div className="payment__section--bottom">
						<div className="__price ">
							<p>
								Subtotal
								<Tooltip
									tipText={
										'The sum total of your plan excluding the transaction fees.'
									}
								>
									<Icon id="info" width="12" height="12" />
								</Tooltip>
							</p>

							<span>₦{subTotal}</span>
						</div>
						<div className="__price">
							<p>Transaction Fee</p>
							<span>₦0.00</span>
						</div>
						<div className="__price total">
							<p>Total</p>
							<span>₦{subTotal}</span>
						</div>
					</div>
					<div className="payment__section--btn">
						<Button
							variant="primary"
							type="submit"
							// className="mt-16"
							block
							onClick={handleClick}
							disabled={planPaymentLoading || !selectedEmployees.length}
						>
							{planPaymentLoading ? 'Processing' : 'Proceed to pay'}
						</Button>
					</div>
				</div>
				<AddEmployeeModal
					addEmployeeModal={addEmployeeModal}
					setAddEmployeeModal={setAddEmployeeModal}
					setAddEmployeeSuccess={setAddEmployeeSuccess}
					setExisitingUserModal={setExisitingUserModal}
					setAddEmployeeDetails={setAddEmployeeDetails}
				/>
				<ModalResponse
					setAddEmployeeSuccess={setAddEmployeeSuccess}
					addEmployeeSuccess={addEmployeeSuccess}
				/>
				<ConfirmExisitingUser
					exisitingUserModal={exisitingUserModal}
					setExisitingUserModal={setExisitingUserModal}
					addEmployeeDetails={addEmployeeDetails}
					setAddEmployeeSuccess={setAddEmployeeSuccess}
				/>
				<ConfirmPaymentWithToken
					confirmDetailsModal={hasCardModal}
					setConfirmDetailsModal={setHasCardModal}
					loading={planPaymentLoading}
					paymentDuration={changeCycle}
					userName={editPlanName}
					userCard={cardDetails}
					amount={subTotal}
					makePaymentWithToken={handlePaymentWithToken}
				/>

				{paymentSuccess && <PaymentSuccessModal />}
			</div>
		</>
	);
};

export default PlanCreation;
