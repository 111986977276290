import React from 'react';
import Icon from './Icon';
import { useNavigate } from 'react-router-dom';

const Goback = ({ linkTo }) => {
	const navigate = useNavigate();
	return (
		<p
			className="prev-btn"
			onClick={() => {
				if (linkTo) {
					navigate(linkTo);
				} else {
					navigate(-1);
				}
			}}
		>
			<Icon id="back" width="14" height="14" className="back-icon" />
			back
		</p>
	);
};

export default Goback;
