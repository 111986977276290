import React from 'react';
import Icon from './Icon';

const BecomePartnercard = ({
	icon,
	mainText,
	subText,
	iconWidth,
	iconHeight,
	color,
}) => {
	return (
		<div className="becomePartner__body--card">
			<div className="__header">
				<span className={color}>
					<Icon width={iconWidth} height={iconHeight} id={icon} />
				</span>
			</div>
			<div className={`__body ${color}`}>
				<h3 className="__body--text">{mainText}</h3>
				<p className="__body--miniText">{subText}</p>
			</div>
		</div>
	);
};

export default BecomePartnercard;
