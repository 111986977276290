import React from 'react';
import Table from './Table';

const ClientsTable = ({ setOpenSideModal, data, setSelectedClient }) => {
	const columns = ['First Name', 'Last Name', 'Client ID', 'Pass Type'];
	return (
		<Table columns={columns} blueHeader={true}>
			{data?.map((row, index) => (
				<tr
					className="pointer grey-bg"
					onClick={() => {
						setOpenSideModal(true);
						setSelectedClient(row);
					}}
					key={index}
				>
					<td className="plan-table ">{row?.firstName}</td>
					<td className="plan-table duration">{row?.lastName}</td>
					<td className="plan-table">{row?.clientId}</td>
					<td className="plan-table">{row?.passName}</td>
				</tr>
			))}
		</Table>
	);
};

export default ClientsTable;
