import React, { useState } from 'react';
import Goback from '../../../components/Goback';
import AddClientsManually from '../../../components/AddClientsManually';
import ImportClients from '../../../components/ImportClients';
// import Icon from '../../../components/Icon';

const ClinetsInfo = () => {
	const [isImportingClients, setIsImportingClients] = useState(false);
	return (
		<div className="clients">
			<Goback />
			<div className="clients__header">
				<h4>Client Information</h4>
				<p className="mini-text">
					Add clients information, (Include: first name, last name, phone
					number(optional), email and Client ID)
				</p>
				<div className="clients__header--pills">
					<div
						className={`clients__header--pill ${
							!isImportingClients ? 'active' : ''
						}`}
						onClick={() => setIsImportingClients(false)}
					>
						<p>Add New Clients </p>
					</div>
					<div
						className={`clients__header--pill ${
							isImportingClients ? 'active' : ''
						}`}
						onClick={() => setIsImportingClients(true)}
					>
						<p>IMPORT CLIENTS</p>
					</div>
				</div>
			</div>
			<div className="clients__body">
					{!isImportingClients ? <AddClientsManually /> : <ImportClients />}
			</div>
		</div>
	);
};

export default ClinetsInfo;
