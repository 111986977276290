import React, { useState } from 'react';
import Icon from '../components/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { logoutCurrnentUser } from '../features/user/userSlice';
import Logo from '../assets/svgs/logo.svg';
import { Outlet } from 'react-router-dom';

const SingleDashBoardHeader = () => {
	const { businessDetails } = useSelector((store) => store.user);
	const dispatch = useDispatch();
	const [openDropdown, setDropdown] = useState(false);
	const logout = () => {
		// navigate('/auth/login');
		dispatch(logoutCurrnentUser());
	};
	return (
		<>
			<nav className={`topnav singleNav`}>
				<div className="topnav--wrapper singleNav--wrapper">
					<div className="brand">
						{' '}
						<div className="dashboard__sidenav--banner">
							<img src={Logo} alt="flance logo" />
						</div>
					</div>

					<div
						className="topnav-right d-iflx al-i-c"
						onMouseOver={() => setDropdown(true)}
						onMouseLeave={() => setDropdown(false)}
					>
						<div className="avatar-circle">
							<div className="avatar-circle__placeholder iflx-center-center">
								<p className="avatar-circle__initials iflx-center-center">
									{businessDetails?.businessName.charAt(0)}
								</p>
							</div>
						</div>
						<div className="d-iflx al-i-c">
							<p className="username mr-8">{businessDetails?.businessName}</p>
							<Icon width="8px" height="5px" id="drop-icon" />

							{openDropdown && (
								<ul className="topnav__dropdown">
									<li onClick={logout}>
										<span className="logoutBtn">Logout</span>
									</li>
								</ul>
							)}
						</div>
					</div>
				</div>
			</nav>
			<Outlet />
		</>
	);
};

export default SingleDashBoardHeader;
