import React from 'react';
import Logo from '../assets/svgs/logo.svg';
import Icon from './Icon';
import { navigations } from '../utils/navigations';
import { Link, useNavigate } from 'react-router-dom';
import Button from './Button';
import { useSelector } from 'react-redux';

const DashboardSIdebar = ({ splitLocation }) => {
	const navigate = useNavigate();
	const { userDetails } = useSelector((store) => store.user);
	return (
		<div className="sidebar--container">
			<div className={` ${'dashboard__sidenav'}`} role="navigation">
				<div>
					<div className="dashboard__sidenav--banner">
						<img src={Logo} alt="flance logo" />
					</div>
					<ul>
						{navigations.map((navigation) => {
							return (
								<Link
									to={navigation.link}
									// onClick={() => setActive(navigation.link)}
									className={`navlinks ${
										splitLocation[1] === navigation.navName ? 'active' : ''
									}`}
									key={navigation.id}
								>
									<Icon
										width="24px"
										height="24px"
										id={`${
											splitLocation[1] === navigation?.navName
												? navigation?.coloredIcon
												: navigation?.icon
										}`}
									/>
									<p>{navigation?.name}</p>
								</Link>
							);
						})}
					</ul>
				</div>
				{userDetails?.resellerAPIKey ? (
					<div>
						<div
							className="partner--btn"
							onClick={() => {
								navigate('/enterprise');
							}}
						>
							<span>
								<Icon width="24" height="24" id="enterprise" />
							</span>
							<p>Enterprise Partner</p>
						</div>
						<hr className="bottom-rule" />
						<a
							href="https://documenter.getpostman.com/view/26212669/2sAXxTbqYa#9144e39f-ded0-466c-9b9c-bbbe7df209a6"
							target="_blank"
							rel="noopener noreferrer"
							className="docs-link"
						>
							API Documentation
							<span>
								<Icon width={10} height={14} id={'right-caret'} />
							</span>
						</a>
					</div>
				) : (
					<div className="partner">
						<>
							{' '}
							<div className="partner__header">
								<span>
									<Icon width="24" height="24" id="enterprise" />
								</span>
								<h4>Become an Enterprise Partner</h4>
							</div>
							<Button
								variant="white"
								size="sm"
								block
								className="btn--block"
								onClick={() => {
									navigate('/become-partner');
								}}
								// disabled={loader}
							>
								{'Start Now'}
							</Button>
						</>
					</div>
				)}
			</div>
		</div>
	);
};

export default DashboardSIdebar;
