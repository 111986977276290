import React from 'react';
import Table from './Table';
import { dateFormat, fmtCurrency } from '../../utils/helpers';

const EnterpriseTransactions = ({ data }) => {
	const columns = ['Plan', 'Description', 'Amount', 'Date', 'TxRef'];
	return (
		<Table columns={columns} blueHeader={true}>
			{data?.map((row, index) => (
				<tr
					className="pointer grey-bg"
					// onClick={() => {
					// 	dispatch(addPlanDetails(row));
					// 	Navigate(`/plan/plans-details/${row?.planId}`);
					// }}
					key={index}
				>
					<td className="plan-table ">{row?.plan}</td>
					<td className="plan-table duration">{row?.descriptionB}</td>
					<td className="plan-table">{fmtCurrency(row?.amount)}</td>
					<td className="plan-table">
						{dateFormat(row?.dateCreated, 'do MMM, yyyy' || '--')}
					</td>

					<td className="plan-table">{row?.transactionReference}</td>
				</tr>
			))}
		</Table>
	);
};

export default EnterpriseTransactions;
