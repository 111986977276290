import React from 'react';
import RouteSwitch from './routes/RouteSwitch';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Toaster } from 'react-hot-toast';

function App() {
	return (
		<>
			<Toaster
				position="top-right"
				containerStyle={{
					zIndex: 99999999,
				}}
				toastOptions={{
					duration: 4000,
					success: {
						style: {
							background: '#EBF7EE',
							zIndex: 999,
						},
					},
					error: {
						style: {
							background: '#FFF3F3',
							zIndex: 999,
						},
					},
					style: {
						borderRadius: '8px',
						maxWidth: '366px',
						minHeight: '48px',
						boxShadow: 'none',
						fontFamily: 'Inter',
						fontWeight: 500,
						fontSize: '14px',
						lineHeight: '17px',
						letterSpacing: '0.01em',
						color: '#000B14',
						textAlign: 'left',
						flex: 'unset',
						zIndex: 999,
					},
				}}
			/>
			<RouteSwitch />
		</>
	);
}

export default App;
