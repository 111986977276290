import React from 'react';

const Thead = ({ columns, blueHeader = false }) => {
	return (
		<tr>
			{columns.map((column, index) => (
				<th key={index} className={`${blueHeader ? 'blueHeader' : ''}`}>
					{column}
				</th>
			))}
		</tr>
	);
};

export default Thead;
