import React, { useEffect, useState } from 'react';
import SideModal from './SideModal';
import Table from '../table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { getEligibleGyms } from '../../features/resellers/resellersSlice';
import Spinner from '../PageLoader';
import { paginate } from '../../utils/helpers';
import Pagination from '../Pagination';

const EligibleGymSideModal = ({
	setOpenSideModal,
	openSideModal,
	selectedPass,
	// handleClick,
}) => {
	const { eligibleGyms, apiKeyLoading } = useSelector(
		(store) => store.resellers
	);

	const [paginateGyms, setPaginateGyms] = useState([]);
	const [paginatedGyms, setPaginatedGyms] = useState([]);
	const [page, setPage] = useState(0);

	const dispatch = useDispatch();

	useEffect(() => {
		if (eligibleGyms?.data) {
			setPaginateGyms(paginate(eligibleGyms?.data, 4));
		}
	}, [eligibleGyms?.data]);

	useEffect(() => {
		if (paginateGyms) {
			setPaginatedGyms(paginateGyms[page]);
		}
	}, [paginateGyms, page]);

	useEffect(() => {
		dispatch(getEligibleGyms(selectedPass?.id));
	}, [selectedPass?.id]);

	const columns = ['Gym Name', 'Email', 'Address'];

	return (
		<SideModal
			headerText={`ELIGIBLE GYMS`}
			openSideModal={openSideModal}
			setOpenSideModal={setOpenSideModal}
			btnText={'Okay'}
			isCart={false}
			handleClick={() => setOpenSideModal(false)}
		>
			{apiKeyLoading ? (
				<Spinner />
			) : (
				<div className="enterprise__body--table">
					<Table columns={columns} blueHeader={true}>
						{paginatedGyms?.map((row, index) => (
							<tr className="pointer grey-bg" key={index}>
								<td className="plan-table ">{row?.name}</td>
								<td className="plan-table duration">{row?.contactEmail}</td>
								<td className="plan-table">{row?.address}</td>
							</tr>
						))}
					</Table>
					<Pagination employees={paginateGyms} page={page} setPage={setPage} />
				</div>
			)}
		</SideModal>
	);
};

export default EligibleGymSideModal;
